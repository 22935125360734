import {
  Autocomplete,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Chip,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  SvgIcon,
  TextField,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import DownloadIcon from "@mui/icons-material/Download";
import { HiSearch } from "react-icons/hi";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import "./style.css";
import { QuickSearchToolbarProps } from "./types";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { checkPermission } from "../../utils/checkPermission";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useState } from "react";
import { truncateText } from "../../utils/truncateText";
import theme from "../../Themes";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import titleCase from "../../utils/titleCase";
import { reformatDates } from "../../utils/reformatDates";
import { generateCodeUrl } from "../../utils/generateCodeUrl";
import { finalUrl } from "../../api/Api.utils";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const SearchComponent = () => (
  <Card sx={{ p: 2 }}>
    <OutlinedInput
      defaultValue=""
      fullWidth
      placeholder="Search customer"
      startAdornment={
        <InputAdornment position="start">
          <SvgIcon color="action" fontSize="small">
            <HiSearch />
          </SvgIcon>
        </InputAdornment>
      }
      sx={{ maxWidth: 500 }}
    />
  </Card>
);

export const LargeScreenTableSearch = (props: QuickSearchToolbarProps) => {
  const { isMobileView, userInfo, masterLocation } = useSelector(
    (state: RootState) => state.globalAppState
  );

  const { filterData } = useSelector(
    (state: RootState) => state.inventoryState
  );

  const { inventoryFilterData, setInventoryFilterData, setPermission } = props;

  const [value, setValue] = useState<string[] | []>([]);

  const onDownload = async () => {
    let {
      name,
      brand,
      code,
      discrepancy,
      grade,
      location,
      range,
      startDate,
      endDate,
    } = inventoryFilterData;

    startDate = reformatDates(startDate);
    endDate = reformatDates(endDate);

    const codeUrl = generateCodeUrl(code);
    const paramsUrl = `${codeUrl}${name ? `name=${name}&` : ""}${
      brand ? `brand=${brand}&` : ""
    }${code ? `code=${code}&` : ""}${
      discrepancy ? `discrepancy=${discrepancy}&` : ""
    }${grade ? `grade=${grade}&` : ""}${range ? `range=${range}&` : ""}${
      location !== "all" ? `locationId=${location}&` : ""
    }${startDate ? `startDate=${startDate}&` : ""}${
      endDate ? `endDate=${endDate}&` : ""
    }`;

    const downloadUrl = `${finalUrl}inventory/kgReport?${paramsUrl}`;

    window.location.href = downloadUrl;
    return;
  };

  const updateinventory = (
    value: string | number | string[],
    name: any,
    event?: any
  ) => {
    if (name === "code") {
      // @ts-ignore
      if (value.find((str: any) => str === "All")) value = [];
    }
    if (name === "startDate" || name === "endDate") {
      if (event) value = event.toISODate();
      else value = "";
    }
    if (name === "location") {
      setPermission(() => {
        const getPermission = checkPermission(
          value as string,
          userInfo,
          "INVENTORY"
        );
        return getPermission;
      });
    }

    setInventoryFilterData({
      ...inventoryFilterData,
      [name]: value !== "All" ? value : "",
    });
  };

  const clearAllFilters = () => {
    setValue([]);
    setInventoryFilterData({
      startDate: "",
      endDate: "",
      name: "",
      code: [],
      brand: "",
      discrepancy: "",
      location: masterLocation.locationId,
      grade: "",
    });
  };

  const renderTags = (value: any, getTagProps: any) => {
    if (value.length === 0) {
      return null;
    }

    const totalSelectedCount = value.length - 1;
    const firstOption = value[0];

    return (
      <>
        <Chip
          label={truncateText(firstOption, 7)}
          {...getTagProps({ index: 0 })}
          size="small"
        />
        {totalSelectedCount > 0 ? <span>{`+${totalSelectedCount}`}</span> : ""}
      </>
    );
  };
  if (filterData?.allowedLocations?.length) {
    return (
      <Box display={"flex"} alignItems={"flex-start"}>
        <Box p={2} className={isMobileView ? "mobileViewCls" : "normalFilter"}>
          <Box className={"resp-select"}>
            <Autocomplete
              multiple
              id="checkboxes-tags-code"
              size="small"
              options={filterData?.code}
              disableCloseOnSelect
              getOptionLabel={(option: string) => option}
              value={value}
              limitTags={1}
              onChange={(event: any, newValue: string[]) => {
                setValue(newValue);
                updateinventory(newValue, `code`);
              }}
              renderTags={renderTags}
              renderOption={(props, option, { selected }) => (
                <li {...props} style={{ width: "100px !important" }}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                    style={{ marginLeft: "-10px" }}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      width: "226px",
                      height: "35px",
                      fontSize: "14px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                    },
                  }}
                  label="Code"
                />
              )}
            />
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="name"
              label={`Product Name`}
              select
              variant="outlined"
              fullWidth
              value={inventoryFilterData?.name}
              onChange={(e) => updateinventory(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.names?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.names?.map((option: any, i: number) => (
                <MenuItem key={i} value={Object.values(option)[0]}>
                  {/* @ts-ignore */}
                  {Object.values(option)[0]}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="brand"
              label={`Brand`}
              select
              variant="outlined"
              fullWidth
              value={inventoryFilterData?.brand}
              onChange={(e) => updateinventory(e.target.value, e.target.name)}
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
              size="small"
            >
              {filterData?.brand?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.brand?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="grade"
              label={`Grade`}
              select
              variant="outlined"
              fullWidth
              value={inventoryFilterData?.grade}
              onChange={(e) => updateinventory(e.target.value, e.target.name)}
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
              size="small"
            >
              {filterData?.grades?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.grades?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="range"
              label={`Range`}
              select
              variant="outlined"
              fullWidth
              value={inventoryFilterData?.range}
              onChange={(e) => updateinventory(e.target.value, e.target.name)}
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
              size="small"
            >
              <MenuItem value={"All"}>None</MenuItem>
              <MenuItem value={"gt0"}>Above Zero</MenuItem>
              <MenuItem value={"0"}>Zero</MenuItem>
              <MenuItem value={"lt0"}>Below Zero</MenuItem>
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="discrepancy"
              label={`Discrepancies`}
              select
              variant="outlined"
              fullWidth
              value={inventoryFilterData?.discrepancy}
              onChange={(e) => updateinventory(e.target.value, e.target.name)}
              size="small"
              // asd
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.discrepancy?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.discrepancy?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option[0].toUpperCase() + option.slice(1)}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                label="Start Date"
                onChange={(e) => updateinventory("", "startDate", e)}
                format="dd/MM/yyyy"
                value={
                  inventoryFilterData?.startDate
                    ? DateTime.fromJSDate(
                        new Date(inventoryFilterData?.startDate)
                      )
                    : null
                }
                maxDate={
                  inventoryFilterData?.endDate
                    ? DateTime.fromJSDate(
                        new Date(inventoryFilterData?.endDate)
                      )
                    : null
                }
                slotProps={{
                  textField: {
                    size: "small",
                    id: "startDate",
                    name: "startDate",
                    InputProps: {
                      sx: {
                        width: "108px",
                        height: "35px",
                        fontSize: "14px",
                      },
                    },
                    InputLabelProps: {
                      sx: {
                        fontSize: "14px",
                      },
                    },
                  },
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box className={"resp-select"}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                format="dd/MM/yyyy"
                label="End Date"
                onChange={(e) => updateinventory("", "endDate", e)}
                value={
                  inventoryFilterData?.endDate
                    ? DateTime.fromJSDate(
                        new Date(inventoryFilterData?.endDate)
                      )
                    : null
                }
                minDate={
                  inventoryFilterData?.startDate
                    ? DateTime.fromJSDate(
                        new Date(inventoryFilterData?.startDate)
                      )
                    : null
                }
                slotProps={{
                  textField: {
                    size: "small",
                    id: "endDate",
                    name: "endDate",
                    InputProps: {
                      sx: {
                        width: "108px",
                        height: "35px",
                        fontSize: "14px",
                      },
                    },
                    InputLabelProps: {
                      sx: {
                        fontSize: "14px",
                      },
                    },
                  },
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
              />
            </LocalizationProvider>
          </Box>

          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="location"
              label={`Location`}
              select
              variant="outlined"
              fullWidth
              value={inventoryFilterData?.location}
              onChange={(e) => updateinventory(e.target.value, e.target.name)}
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
              size="small"
            >
              {filterData?.location?.length ? (
                <MenuItem value={"all"}>All</MenuItem>
              ) : (
                <MenuItem value={""}></MenuItem>
              )}
              {filterData?.location?.length ? (
                filterData?.location?.map((option: any, i: number) => (
                  <MenuItem key={i} value={option._id}>
                    {titleCase(option.fullName)}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={""}></MenuItem>
              )}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <Button
              variant="outlined"
              onClick={clearAllFilters}
              sx={{
                width: "108px",
                height: "35px",
                fontSize: "14px",
                color: theme.palette.indicator.main,
              }}
            >
              Clear All
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            flex: "1",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "10px",
            marginBottom: "15px",
          }}
        >
          <ButtonGroup aria-label="outlined primary button group">
            <Tooltip title="Download Report">
              <Button
                startIcon={<DownloadIcon />}
                variant="contained"
                size="large"
                color="primary"
                sx={{
                  width: "54px",
                  height: "35px",
                  "& .MuiButton-startIcon": {
                    marginRight: "0",
                    marginLeft: "0",
                  },
                }}
                onClick={onDownload}
              />
            </Tooltip>
          </ButtonGroup>
        </Box>
      </Box>
    );
  }
  return <></>;
};

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

export const TableSearch = (props: QuickSearchToolbarProps) => {
  return (
    <Box>
      <LargeScreenTableSearch {...props} />
    </Box>
  );
};
